var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "기본정보", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육명",
                    value: _vm.educationInfo.educationName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육과정",
                    value: _vm.educationInfo.educationCourseName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육종류1",
                    value: _vm.educationInfo.educationKindCdLargeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육종류2",
                    value: _vm.educationInfo.educationKindCdSmallName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사업장",
                    value: _vm.educationInfo.plantName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "주관부서",
                    value: _vm.educationInfo.departmentDeptName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육방법",
                    value: _vm.educationInfo.educationMethodName
                      ? _vm.educationInfo.educationMethodName
                      : "-",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "문제풀이",
                    value: _vm.educationInfo.eduQuestionFlag,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.educationInfo.educationMethodCd == "EMC999999",
              expression: "educationInfo.educationMethodCd == 'EMC999999'",
            },
          ],
          staticClass: "cardClassDetailForm mobilevod mobilevod-hide",
          attrs: { title: "동영상 시청", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "player-center text-center col-12" },
              [
                _c("video-player", {
                  ref: "videoPlayer",
                  attrs: { options: _vm.playerOptions },
                  on: {
                    playing: _vm.onPlayerStart,
                    play: _vm.onPlay,
                    pause: _vm.onPause,
                    ended: function ($event) {
                      return _vm.onPlayerEnded($event)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vod-label-layer col-12" }, [
              !_vm.disabled
                ? _c("div", { staticClass: "vod-label-div text-center" }, [
                    _vm._v(
                      _vm._s(_vm.$language("재생시간")) +
                        " : " +
                        _vm._s(_vm.vodPlayDuration) +
                        " / " +
                        _vm._s(_vm.vodTotalDuration)
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vod-label-div text-center" },
                [
                  _vm.isFirstPlay &&
                  !_vm.isPlay &&
                  !_vm.isEduEnd &&
                  !_vm.disabled &&
                  _vm.educationInfo.checkCompleteFlag != "Y"
                    ? _c("c-btn", {
                        staticClass: "full-width",
                        attrs: {
                          label: "처음부터 재생",
                          icon: "skip_next",
                          color: "orange",
                        },
                        on: { btnClicked: _vm.onPlayerPlayFirst },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vod-label-div text-center" },
                [
                  !_vm.isEduEnd &&
                  !_vm.disabled &&
                  _vm.educationInfo.checkCompleteFlag != "Y"
                    ? _c("c-btn", {
                        staticClass: "full-width",
                        attrs: {
                          label: _vm.playLabel,
                          icon: _vm.playIcon,
                          color: _vm.playColor,
                        },
                        on: { btnClicked: _vm.onPlayerPlay },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                ((_vm.educationInfo.educationMethodCd == "EMC999999" &&
                  _vm.isEnd &&
                  !_vm.isPlay) ||
                  _vm.educationInfo.educationMethodCd != "EMC999999") &&
                ((_vm.educationInfo.eduQuestionFlag == "Y" &&
                  _vm.educationInfo.isQuestionFlag == "Y") ||
                  _vm.educationInfo.eduQuestionFlag != "Y"),
              expression:
                "((educationInfo.educationMethodCd == 'EMC999999' && isEnd && !isPlay) || educationInfo.educationMethodCd != 'EMC999999') \n  && (educationInfo.eduQuestionFlag == 'Y' && educationInfo.isQuestionFlag == 'Y' || educationInfo.eduQuestionFlag != 'Y')",
            },
          ],
          staticClass: "cardClassDetailInfo",
          attrs: { title: "서명 및 의견" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _vm.questionWithoutVod || _vm.questionWithVod
              ? _c("div", { staticClass: "vod-label-div text-center" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$language(
                        "서명/의견은 문제풀이 후 입력할 수 있습니다."
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm.educationInfo.isQuestionFlag === "Y"
              ? _c("div", { staticClass: "vod-label-div text-center" }, [
                  _vm._v(
                    _vm._s(_vm.$language("문제풀이 획득점수/통과기준점수")) +
                      " : " +
                      _vm._s(_vm.educationInfo.score) +
                      " / " +
                      _vm._s(_vm.educationInfo.eduQuestionPassScore)
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "div",
                    { staticClass: "items-center all-pointer-events" },
                    [
                      _c(
                        "b",
                        [
                          _c("font", { attrs: { id: "signatureTitle" } }, [
                            _vm._v(
                              "  " +
                                _vm._s(
                                  _vm.$language(
                                    "문제풀이 획득점수/통과기준점수"
                                  )
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("VueSignaturePad", {
                    ref: "signaturePad",
                    attrs: {
                      id: "signature",
                      height: "200px",
                      options: _vm.options,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "40px !important" } },
                [
                  _c("c-textarea", {
                    attrs: {
                      disabled: _vm.disabled,
                      label: "의견",
                      name: "opinionContent",
                    },
                    model: {
                      value: _vm.educationInfo.opinionContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.educationInfo, "opinionContent", $$v)
                      },
                      expression: "educationInfo.opinionContent",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "p-3" }, [
        _vm.educationInfo.checkCompleteFlag === "Y"
          ? _c(
              "div",
              {
                staticClass: "vod-label-div container text-center",
                staticStyle: { width: "100% !important" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$language("이수완료")) +
                    " : " +
                    _vm._s(_vm.educationInfo.evaluationDate)
                ),
              ]
            )
          : _vm._e(),
        _vm.disabled
          ? _c(
              "div",
              {
                staticClass: "vod-label-div container text-center",
                staticStyle: { width: "100% !important" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$language("교육완료 또는 이수완료 되었습니다."))
                ),
              ]
            )
          : _vm._e(),
        !_vm.disabled && _vm.educationInfo.educationMethodCd == "EMC999999"
          ? _c("div", { staticClass: "vod-label-div-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$language(
                      "※ 재생/정지/완료 버튼을 클릭하지 않고 창을 닫을 경우 동영상 재생이력이 저장되지 않습니다."
                    )
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.educationInfo.checkCompleteFlag != "Y" &&
                        _vm.educationInfo.isQuestionFlag != "Y" &&
                        _vm.educationInfo.eduQuestionFlag == "Y" &&
                        ((_vm.educationInfo.educationMethodCd == "EMC999999" &&
                          _vm.isEnd &&
                          !_vm.isPlay) ||
                          _vm.educationInfo.educationMethodCd != "EMC999999"),
                      expression:
                        "educationInfo.checkCompleteFlag != 'Y' && educationInfo.isQuestionFlag != 'Y' && educationInfo.eduQuestionFlag == 'Y' && \n        (educationInfo.educationMethodCd == 'EMC999999' && isEnd && !isPlay || educationInfo.educationMethodCd != 'EMC999999')",
                    },
                  ],
                  attrs: {
                    flat: "",
                    label: "문제풀이",
                    icon: "task_alt",
                    color: "teal-6",
                  },
                  on: { btnClicked: _vm.openQuiz },
                }),
                _vm.isEnd &&
                !_vm.isPlay &&
                !_vm.isEduEnd &&
                !_vm.disabled &&
                (_vm.educationInfo.isQuestionFlag === "Y" ||
                  _vm.educationInfo.eduQuestionFlag === "N")
                  ? _c(
                      "span",
                      [
                        _c("c-btn", {
                          attrs: {
                            flat: "",
                            label: "서명지우기",
                            icon: "remove",
                            color: "red",
                          },
                          on: { btnClicked: _vm.eraseSignature },
                        }),
                        _c("c-btn", {
                          attrs: {
                            flat: "",
                            label: "이수완료",
                            icon: "task_alt",
                            color: "blue",
                          },
                          on: { btnClicked: _vm.saveAsSvg },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.educationInfo.educationMethodCd != "EMC999999" &&
                _vm.educationInfo.eduQuestionFlag === "Y" &&
                _vm.educationInfo.isQuestionFlag === "Y" &&
                _vm.educationInfo.checkCompleteFlag !== "Y"
                  ? _c(
                      "span",
                      [
                        _c("c-btn", {
                          attrs: {
                            flat: "",
                            label: "서명지우기",
                            color: "red",
                            showLoading: false,
                          },
                          on: { btnClicked: _vm.eraseSignature },
                        }),
                        _c("c-btn", {
                          attrs: {
                            flat: "",
                            label: "이수완료",
                            color: "blue",
                            showLoading: false,
                          },
                          on: { btnClicked: _vm.saveAsSvg },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }